import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import {
  Section,
  SectionPageTitle,
  Container,
  SectionTitle,
  SectionDescription,
  SectionLineDescription
} from "../components/Section";
import { HeroBanner, HeroImage } from "../components/HeroBanner";
import OrderBanner from "../images/order-banner.jpg";

const SectionCustom = styled(Section)`
  overflow: hidden;
  .container {
    @media (min-width: 1410px) {
      max-width: inherit;
      padding-right: 0;
      padding-left: 0;
    }
  }
`;
const Grid = styled.div`
  .grid-item {
    position: relative;
    @media (min-width: 1410px) {
      padding-right: calc((100% - 1350px) / 2);
      padding-left: 0;
    }
    .grid-figure {
      @media (min-width: 768px) {
        border-radius: 0 130px 130px 0;
      }
      @media (min-width: 992px) {
        border-radius: 0 230px 230px 0;
      }
    }
    &:nth-child(even) {
      @media (min-width: 768px) {
        flex-direction: row-reverse;
      }
      @media (min-width: 1410px) {
        padding-left: calc((100% - 1350px) / 2);
        padding-right: 0;
      }
      .grid-figure {
        @media (min-width: 768px) {
          border-radius: 130px 0 0 130px;
        }
        @media (min-width: 992px) {
          border-radius: 230px 0 0 230px;
        }
      }
      .grid-content {
        &:after {
          right: 0;
          left: auto;
          clip-path: polygon(0px 0px, 100% 100%, 100% 0);
        }
      }
    }
    + .grid-item {
      margin-top: 30px;
      @media (min-width: 992px) {
        margin-top: 40px;
      }
      @media (min-width: 1200px) {
        margin-top: 60px;
      }
      @media (min-width: 1600px) {
        margin-top: 80px;
      }
    }
  }
`;
const GridItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -15px;
  flex-direction:column-reverse;
  @media (min-width: 768px) {
    margin: 0 -20px;
    flex-direction:row;
  }
  @media (min-width: 992px) {
    margin: 0 -30px;
  }
`;
const GridItemLeft = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  @media (min-width: 768px) {
    padding: 0 20px 0 0;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  @media (min-width: 992px) {
    padding: 0 30px 0 0;
    flex: 0 0 40%;
    max-width: 40%;
  }
`;
const GridItemRight = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  @media (min-width: 768px) {
    padding: 0 20px;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  @media (min-width: 992px) {
    padding: 0 30px;
    flex: 0 0 60%;
    max-width: 60%;
  }
`;

const Figure = styled.div`
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow:hidden;
  margin-bottom:0;
  .gatsby-image-wrapper {
    display: inline-block;
    position: relative;
    z-index: 1;
    width: 100%;
  }
`;

const Work = styled.div`
    counter-reset: section;
`
const WorkItem = styled.div`
    position:relative;
    padding-left:55px;
    @media (min-width: 768px) {
        padding-left:65px;
    }
    @media (min-width: 992px) {
        padding-left:75px;
    }
    @media (min-width: 1200px) {
        padding-left:85px;
    }
    @media (min-width: 1600px) {
        padding-left:95px;
    }
    
    &:before{
        border-radius:10px;
        display:flex;
        align-items:center;
        justify-content:center;
        background-color:#eef0fd;
        counter-increment: section;
        content: counter(section);
        color:#373636;
        position: absolute;
        left: 0;
        top: 0;
        
        font-family: 'Oxanium', serif;
        font-weight: 700;
        font-size: 16px;
        line-height: 30px;
        width:40px;
        height:40px;
        @media (min-width: 768px) {
            font-size: 18px;
            line-height: 30px;
            width:45px;
            height:45px;
        }
        @media (min-width: 992px) {
            font-size: 20px;
            line-height: 30px;
            width:50px;
            height:50px;
        }
        @media (min-width: 1200px) {
            font-size: 24px;
            line-height: 34px;
            width:60px;
            height:60px;
        }
        @media (min-width: 1600px) {
            font-size: 28px;
            line-height: 38px;
            width:65px;
            height:65px;
        }
    }
    + .work-item{
        margin-top:15px;
        @media (min-width: 1200px) {
            margin-top:20px;
        }
        @media (min-width: 1600px) {
            margin-top:30px;
        }
    }
`
const WorkCard = styled.div`
    background-color:#EEF0FD;
    border-radius:10px;
    padding:15px;
    @media (min-width: 992px) {
        padding:20px;
    }
    @media (min-width: 1200px) {
        padding:30px;
    }
    @media (min-width: 1600px) {
        padding:40px;
    }
`
const WorkCardBody = styled.div`
    > p{
        margin-bottom:0;
        + p{
            margin-top:15px;
        }
    }
`



const OrderingProcessPage = ({ location, data }) => {
  const breadcrumbSchema = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
        {
        "@type": "ListItem",
        position: 1,
        name: "Home Page",
        item: "https://www.engineeredmetalbuildings.com/",
        },
        {
        "@type": "ListItem",
        position: 2,
        name: "Ordering Process",
        item: `https://www.engineeredmetalbuildings.com/ordering-process/`,
        },
    ],
  };
  const schema = [breadcrumbSchema]
  return (
    <Layout location={location}>
      <Seo
        title="Metal Building Ordering Process - EMB Metal Buildings"
        description="EMB Metal Buildings simplifies buying with an easy steel building ordering process and expert guidance. Place your order today!"
        schemaMarkup={schema}
      />
      <HeroBanner>
        <HeroImage bgImg={`url(${OrderBanner})`} opacity="1"></HeroImage>
      </HeroBanner>
      <Section
        ept="60px"
        epb="60px"
        xpt="40px"
        xpb="40px"
        pt="30px"
        pb="30px"
        bgColor="#fff"
        className="section-order"
      >
        <SectionLineDescription mb="0">
          <Container>
            <SectionPageTitle>Our Easy Ordering Process</SectionPageTitle>
            <SectionDescription>
                <p>At EMB Metal Buildings, we make the process of purchasing your custom metal building as simple and straightforward as possible. From choosing the perfect design to the final installation, our goal is to ensure you have a hassle-free experience. </p>          
            </SectionDescription>
          </Container>
        </SectionLineDescription>
      </Section>

      <Section      
        pt="0"
        pb="0"
        bgColor="#fff"
        className="section-work"
      >
        <Container className="container">
          <SectionTitle mb="20px">Here’s How It Works</SectionTitle>          
          <Work className="work">
            <WorkItem className="work-item">
                <WorkCard className="work-card">
                    <WorkCardBody className="work-body">
                        <h3 className="h5">Choose and Design Your Building</h3>
                        <p>Begin by selecting the type of metal building that suits your needs. Whether it’s a garage, carport, barn, or commercial structure, you have the freedom to design your building according to your preferences. Choose the size, roof style, doors, windows, and any other features you need. Our team is here to help you make the right choices. </p>
                    </WorkCardBody>
                </WorkCard>
            </WorkItem>
            
            <WorkItem className="work-item">
                <WorkCard className="work-card">
                    <WorkCardBody className="work-body">
                        <h3 className="h5">Place Order and Make the Payment</h3>
                        <p>Once you’ve finalized your design, it’s time to place your order. We’ll provide you with a detailed quote, and once you’re satisfied, you can go ahead and make the payment. We offer flexible financing and rent-to-own options to make the process easy and convenient for you.</p>
                    </WorkCardBody>
                </WorkCard>
            </WorkItem>
           
            <WorkItem className="work-item">
                <WorkCard className="work-card">
                    <WorkCardBody className="work-body">
                        <h3 className="h5">Prepare Your Site for Installation</h3>
                        <p>Before we deliver and install your building, it’s important to prepare the site. Ensure the area is level and free of any debris. If you need assistance with site preparation, our team can offer guidance to help you get everything ready. However, you need to hire a third-party contractor for site preparation work. </p>
                    </WorkCardBody>
                </WorkCard>
            </WorkItem>
           
            <WorkItem className="work-item">
                <WorkCard className="work-card">
                    <WorkCardBody className="work-body">
                        <h3 className="h5">Let Us Start Manufacturing Your Building</h3>
                        <p>After your order is placed and the site is prepared, our skilled team will begin manufacturing your building. We use high-quality steel and follow strict quality standards to ensure your building is durable and long-lasting. </p>
                    </WorkCardBody>
                </WorkCard>
            </WorkItem>
            
            <WorkItem className="work-item">
                <WorkCard className="work-card">
                    <WorkCardBody className="work-body">
                        <h3 className="h5">Free Delivery and Installation</h3>
                        <p>Once your building is ready, we’ll schedule a convenient time for delivery and installation. Our team will bring your building to your site and handle the entire installation process at no additional cost to you. We’ll make sure everything is set up correctly and answer any questions you may have.</p>
                    </WorkCardBody>
                </WorkCard>
            </WorkItem>
          </Work>
        </Container>
      </Section>

      <SectionCustom
        ept="60px"
        epb="60px"
        xpt="40px"
        xpb="40px"
        pt="30px"
        pb="30px"
        bgColor="#fff"
        className="section-work"
      >
        <Container className="container">
          <Grid className="grid">
            <GridItem className="grid-item">
              <GridItemLeft>
                <Figure className="grid-figure">
                  <StaticImage src="../images/new-structure.jpg" alt="Enjoy Your New Structure from EMB Metal Buildings" />
                </Figure>
              </GridItemLeft>
              <GridItemRight className="grid-content">
                <SectionTitle>Enjoy Your New Structure from EMB Metal Buildings</SectionTitle>
                <SectionDescription>
                  <hr />
                  <p>That’s it! With your new metal buildings fully installed, you can start using it right away. Whether you’re using it for storage, business, or any other purpose, you’ll enjoy the strength and reliability of a structure built to last. If you need help or have any questions, our team is always here to help.</p>
                  <p>Contact us at <a href="tel:2085721441" aria-label="phone number">(208) 572-1441</a>, and our professionals will help you design, customize, and realize the structure you need.</p>
                </SectionDescription>
              </GridItemRight>
            </GridItem>
          </Grid>
        </Container>
      </SectionCustom>      
    </Layout>
  );
};
export default OrderingProcessPage;
